export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const ADD_SITE_SUCCESS = "ADD_SITE_SUCCESS";
export const ADD_SITE_FAILURE = "ADD_SITE_FAILURE";
export const GET_SITES_SUCCESS = "GET_SITES_SUCCESS";
export const GET_SITES_FAILURE = "GET_SITES_FAILURE";
export const FILTER_SITES = "FILTER_SITES";
export const FILTER_SITES_FAILURE = "FILTER_SITES_FAILURE";
export const UPDATE_SITE = "UPDATE_SITE";
export const UPDATE_SITE_FAILURE = "FILTER_SITES_FAILURE";
export const UPDATE_SITE_SUCCESS = "UPDATE_SITE_SUCCESS";
export const UPDATE_SITE_LOCAL_DETAILS = "UPDATE_SITE_LOCAL_DETAILS";
export const UPDATE_SITE_LOCAL_DETAILS_FAILURE = "UPDATE_SITE_LOCAL_DETAILS_FAILURE";
export const UPDATE_TIMINIG_SUCCESS = "UPDATE_TIMINIG_SUCCESS";
export const UPDATE_TIMINIG_FAILURE = "UPDATE_TIMINIG_FAILURE";
export const UPDATE_SITE_IMAGE_SUCCESS = "UPDATE_SITE_IMAGE_SUCCESS";
export const UPDATE_SITE_IMAGE_FAILURE = "UPDATE_SITE_IMAGE_FAILURE";
export const ADD_KEY_CONTACTS = "ADD_KEY_CONTACTS";
export const ADD_KEY_CONTACTS_FAILURE = "ADD_KEY_CONTACTS_FAILURE";
export const GET_KEY_CONTACTS = "GET_KEY_CONTACTS";
export const GET_KEY_CONTACTS_FAILURE = "GET_KEY_CONTACTS_FAILURE";
export const GET_SITES_BY_ID_SUCCESS = "GET_SITES_BY_ID_SUCCESS";
export const GET_SITES_BY_ID_FAILURE = "GET_SITES_BY_ID_FAILURE";
export const GET_ADDRESS_ON_POST_CODE_SUCCESS = "GET_ADDRESS_ON_POST_CODE_SUCCESS";
export const GET_ADDRESS_ON_POST_CODE_FAILURE = "GET_ADDRESS_ON_POST_CODE_FAILURE";
export const GET_SITE_INFORMATION = "GET_SITE_INFORMATION";
export const SET_SITE_INFORMATION = "SET_SITE_INFORMATION";
export const SET_SITE_INFORMATION_FAILURE = "SET_SITE_INFORMATION_FAILURE";
export const GET_SITE_INFORMATION_FAILURE = "GET_SITE_INFORMATION_FAILURE";
export const SAVE_SITE_AREA_INFORMATION = "SAVE_SITE_AREA_INFORMATION";
export const SAVE_SITE_AREA_INFORMATION_FAILURE = "SAVE_SITE_AREA_INFORMATION_FAILURE";
export const GET_SITE_AREA_INFORMATION = "SAVE_SITE_AREA_INFORMATION";
export const GET_SITE_AREA_INFORMATION_FAILURE = "SAVE_SITE_AREA_INFORMATION_FAILURE";
export const SAVE_SITE_SECURITY_INFORMATION = "SAVE_SITE_AREA_INFORMATION";
export const SAVE_SITE_SECURITY_INFORMATION_FAILURE = "SAVE_SITE_AREA_INFORMATION_FAILURE";
export const GET_SITE_SECURITY_INFORMATION = "SAVE_SITE_AREA_INFORMATION";
export const GET_SITE_SECURITY_INFORMATION_FAILURE = "SAVE_SITE_AREA_INFORMATION_FAILURE";
export const SAVE_UTILITY_ENERGY_DETAILS= "GET_UTILITY_ENERGY_DETAILS";
export const SAVE_UTILITY_ENERGY_DETAILS_FAILURE= "GET_UTILITY_ENERGY_DETAILS_FAILURE";
export const GET_UTILITY_ENERGY_DETAILS= "GET_UTILITY_ENERGY_DETAILS";
export const GET_UTILITY_ENERGY_DETAILS_FAILURE= "GET_UTILITY_ENERGY_DETAILS_FAILURE";
export const GET_USER_ALL = "GET_USER_ALL";
export const GET_USER_ALL_EXTERNAL = "GET_USER_ALL_EXTERNAL";

export const GET_SITE_LAYOUT = "GET_SITE_LAYOUT";
export const SAVE_SITE_LAYOUT = "SAVE_SITE_LAYOUT";
export const SAVE_SITE_LAYOUT_FAILURE = "SAVE_SITE_LAYOUT_FAILURE";
export const GET_SITE_LAYOUT_FAILURE = "GET_SITE_LAYOUT_FAILURE";
export const SELECT_GLOBAL_SITE = 'SELECT_GLOBAL_SITE';
export const SAVE_SITE_UTILITY_INFORMATION = 'SAVE_SITE_UTILITY_INFORMATION';
export const SAVE_SITE_UTILITY_INFORMATION_FAILURE = 'SAVE_SITE_UTILITY_INFORMATION_FAILURE';
export const GET_SITE_UTILITY_INFORMATION = 'GET_SITE_UTILITY_INFORMATION';
export const GET_SITE_LIFTS_INFORMATION = "GET_SITE_LIFTS_INFORMATION";
export const SAVE_SITE_LIFTS_INFORMATION_FAILURE = "SAVE_SITE_LIFTS_INFORMATION_FAILURE";
export const SAVE_SITE_LIFTS_INFORMATION = "SAVE_SITE_LIFTS_INFORMATION";
export const SAVE_SITE_LANDSCAPED_INFORMATION = "SAVE_SITE_LANDSCAPED_INFORMATION";
export const SAVE_SITE_LANDSCAPED_INFORMATION_FAILURE = "SAVE_SITE_LANDSCAPED_INFORMATION_FAILURE";
export const GET_SITE_LANDSCAPE_INFORMATION = "GET_SITE_LANDSCAPE_INFORMATION";
export const SET_SITE_LOADER = "SET_SITE_LOADER";
export const SET_SITE_ASSET_LOADER = "SET_SITE_ASSET_LOADER";
export const RESET_SITE_MESSAGES ="RESET_SITE_MESSAGES";
//TODO: move to documents action
export const GET_DOCUMENTS_ROOT_FOLDER = "GET_DOCUMENTS_ROOT_FOLDER";
export const GET_DOCUMENTS_ROOT_FOLDER_FAILURE = "GET_DOCUMENTS_ROOT_FOLDER_FAILURE";
export const GET_DOCUMENTS_SUB_FOLDER_FILES = "GET_DOCUMENTS_SUB_FOLDER_FILES";
export const GET_DOCUMENTS_SUB_FOLDER_FILES_FAILURE = "GET_DOCUMENTS_SUB_FOLDER_FILES_FAILURE";
export const UPDATE_DOCUMENT_FILE_SUCCESS = "UPDATE_DOCUMENT_FILE_SUCCESS";
export const UPDATE_DOCUMENT_FILE_FAILURE = "UPDATE_DOCUMENT_FILE_FAILURE";
export const CREATE_FOLDER = "CREATE_FOLDER";

// user login logout
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const SET_SIDEBAR_VIEW = "SET_SIDEBAR_VIEW";
